import axios from "./axios";
const API_URL = process.env.REACT_APP_API_URL;
export const callAPIDownload = async (filename)=>{
    try {
      let url =  `${API_URL}`+filename
      const result = await axios({
        method: "GET",
        url: url,
        responseType: 'blob',
      })
  
      return {
        errCode:0,
        result: result
      };
    } catch (error) {
      return {
        result: null,
        errCode: 1,
        errDetail: "system error",
      };
    }
  };
  export const getBase64Single = (file) => {
    const reader = new FileReader();
    
    return new Promise((resolve) => {
      reader.onload = (ev) => {
        resolve(ev.target.result);
      };
      reader.readAsDataURL(file);
    });
  };
  
  export const urlImage = async (file) => {
    if (!file) {
      return null;
    }
    if (file.includes('file://')) {
      return file;
    }
    const { errCode, result } = await callAPIDownload(`download-image?fileKey=${file}`);
  
    if (errCode) {
      return '';
    };
    const urlImg = await getBase64Single(result);
    return urlImg
  };

  export const uploadImage = async (formData) => {
    try {
      const { errCode, errDetail, result } = await axios({
        method: "POST",
        url: `${API_URL}`+"upload-image",
        headers:
        {
          'Content-Type': 'application/json'
        },
        data: formData,
      });
      return {
        errCode: errCode,
        errDetail: errDetail,
        result: result,
      };
    } catch (error) {
      return {
        result: null,
        errCode: 1,
        errDetail: "system error",
      };
    }
  };
  
  export const urlFile = async (file) => {
    if (!file) {
      return null;
    }
    if (file.includes('file://')) {
      return file;
    }
    const { errCode, result } = await callAPIDownload(`download-file?fileKey=${file}`);
  
    if (errCode) {
      return '';
    };
    const urlImg = await getBase64Single(result);
    return urlImg
  };  
  export const uploadFile = async (body) => {
    try {
  
      let data =body
      const { errCode, errDetail, result } = await axios({
        method: "POST",
        url: `${API_URL}`+"upload-file",
        headers:
        {
          'Content-Type': 'application/json'
        },
        data: data,
      });
      return {
        errCode: errCode,
        errDetail: errDetail,
        result: result,
      };
    } catch (error) {
      return {
        result: null,
        errCode: 1,
        errDetail: "system error",
      };
    }
  };
  export const uploadFolder = async (body) =>{
    try {
      const token = localStorage.getItem("id_token")
      const { errCode, errDetail, result } = await axios({
          method: 'POST',
          url: `${API_URL}`+'upload-content',
          data: body,
          headers: { 'Content-Type': 'multipart/form-data',
            token: token },
        });
      return {
        errCode: errCode,
        errDetail: errDetail,
        result: result,
      };
    } catch (error) {
      return {
        result: null,
        errCode: 1,
        errDetail: "system error",
      };
    }
  }