import axios from "./axios";
import moment from "moment";
import { objectToQueryString } from "../library/helpers/formatData.js";
import { urlImage } from "./helperFunction";
const noImageURL = "../../images/noImage.jpg"
const API_URL = process.env.REACT_APP_API_URL;

export const getAll = async (query = null) =>{
    try{
        let url = `${API_URL}`+`slider`
        if(query!==null){
          url+=`?${objectToQueryString(query)}`
        }
        let { errCode, errDetail, result } = await axios({
          method: "GET",
          url: url,
        });
        result.data = await formatData(result.data)
        return {
          errCode: errCode,
          errDetail: errDetail,
          result: result,
        };
    }catch(error) {
        return {
            result: null,
            errCode: 1,
            errDetail: "system error",
        };
    }
}

export const formatData=async (result)=>{
  try{
    for(let i =0;i < result.length;i++){
      if(result[i].thumbnailUrl!=null){
        result[i].displayThumbnailUrl= await urlImage(result[i].thumbnailUrl)
      }
      else if(result[i].thumbnailUrl==null){
        result[i].displayThumbnailUrl= noImageURL
      }
      result[i].createAt = moment(result[i].createAt).format('DD/MM/YYYY');
    }
    return result
  }catch(error){
    console.log(error)
    return null;
  }
}
export const createSlider = async (body)=>{
  try {
    let data =body
    const url = `${API_URL}`+"slider"
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail, result } = await axios({
      method: "POST",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch(error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
}

export const updateSlider = async (body,code) => {
  try {
    const url = `${API_URL}`+"slider/"+code
    let data =body
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail, result } = await axios({
      method: "PATCH",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};

export const deleteSlider = async (code) => {
  try {
    const url = `${API_URL}`+"slider/"+code
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail, result } = await axios({
      method: "DELETE",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};
