const REDUCER = "@ANSWER/";

const actions = {
  GET_LIST_ANSWER: `${REDUCER}GET_LIST_ANSWER`,
  SET_LIST_ANSWER: `${REDUCER}SET_LIST_ANSWER`,
  SET_IS_LOADING: `${REDUCER}SET_IS_LOADING`,
  SET_IS_LOADING_STATISTIC: `${REDUCER}SET_IS_LOADING_STATISTIC`,
  GET_STATISTIC: `${REDUCER}GET_STATISTIC`,
  SET_STATISTIC: `${REDUCER}SET_STATISTIC`,
};
export default actions;
