import actions from "./actions";

const initState = {
  listUser: [],
  isLoading: false,
  currentUser: {},
};

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_LIST_USER:
      return {
        ...state,
        listUser: action.payload,
        isLoading: false,
      };
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    default:
      return state;
  }
}
