import axios from "./axios";
import { objectToQueryString } from "../library/helpers/formatData.js";
const API_URL = process.env.REACT_APP_API_URL;
export const getListContent = async (code,query=null) => {
  try {
    let url = `${API_URL}`+`admin/books/${code}`
    if(query!==null){
      url+=`?${objectToQueryString(query)}`
    }
    const token = localStorage.getItem("id_token")
    let { errCode, errDetail, result } = await axios({
      method: "GET",
      url: url,
      headers: {
        token : token
      }
    });
    result = await formatData(result)
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};
export const setContent = async (body) => {
  try {

    let data =body
    const { errCode, errDetail, result } = await axios({
      method: "POST",
      url: `${API_URL}`+"content",
      headers:
      {
        'Content-Type': 'application/json'
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};

export const updateContent = async(body,code)=>{
    try {

      let data =body
      const url = `${API_URL}`+"content/"+code
      const { errCode, errDetail, result } = await axios({
        method: "PATCH",
        url: url,
        headers:
        {
          'Content-Type': 'application/json'
        },
        data: data,
      });
      return {
        errCode: errCode,
        errDetail: errDetail,
        result: result,
      };
  }catch(error){
    console.log(error)
    return {
      result:null,
      errCode:1,
      errDetail:"system error"
    }
  }
}

export const formatData =async (data) =>{
  try{
    for(let i =0;i<data.content.length;i++){
      data.content[i].bookName = data.title
      data.content[i].code = i
      data.content[i].chapter = i+1
    }
    return data

  }catch(error){
    return null;
  }
}
