import axios from "./axios";
import moment from "moment";
import { objectToQueryString } from "../library/helpers/formatData.js";
const API_URL = process.env.REACT_APP_API_URL;
export const getListBlog = async (query = null) => {
  try {
    let url = `${API_URL}` + "blog"

    if (query !== null) {
      url += `?${objectToQueryString(query)}`
    }
    const { errCode, errDetail, result } = await axios({
      method: "GET",
      url: url,
    });
    result.data = await formatData(result.data)
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};

export const getBlog = async (code) => {
  try {
    let url = `${API_URL}` + `blog/${code}`
    let { errCode, errDetail, result } = await axios({
      method: "GET",
      url: url,
    });
    result.createAt = moment(result.createAt).format('DD/MM/YYYY');
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};

export const setBlog = async (body) => {
  try {
    let data = body
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail, result } = await axios({
      method: "POST",
      url: `${API_URL}` + "blog",
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};

export const updateBlog = async (code, body) => {
  try {
    let data = body
    let url = `${API_URL}` + `blog/${code}`
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail, result } = await axios({
      method: "PUT",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};


export const deleteBlog = async (code) => {
  try {
    const url = `${API_URL}` + "destroy/blog/" + code
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail } = await axios({
      method: "PUT",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};


export const formatData = async (result) => {
  try {
    for (let i = 0; i < result.length; i++) {
      result[i].createAt = moment(result[i].createAt).format('DD/MM/YYYY');
    }
    return result
  } catch (error) {
    console.log(error)
  }
}