import { call, put, takeLatest, all } from "@redux-saga/core/effects";
import { getListAnswer } from "@iso/services/answer";
import { notification } from "@iso/components";
import actions from "./actions";
import { getStatisticAnswer } from "../../services/answer";
function* fetchListAnswer(action) {
  yield put({ type: actions.SET_IS_LOADING, payload: true });
  const { result, errCode, errDetail } = yield call(
    getListAnswer,
    action.payload
  );
  if (errCode) {
    return notification("error", errDetail);
  }
  yield put({ type: actions.SET_LIST_ANSWER, payload: result.answers });
}
function* fetchListStatistic(action) {
  yield put({ type: actions.SET_IS_LOADING_STATISTIC, payload: true });
  let firstDateOfMonth = action.payload.clone().startOf("month");
  const lastDateOfMonth = action.payload.clone().endOf("month");
  const listCount = [];
  while (firstDateOfMonth.isSameOrBefore(lastDateOfMonth, "date")) {
    const { result, errCode, errDetail } = yield call(getStatisticAnswer, {
      from: new Date(
        firstDateOfMonth.clone().startOf("week").add(1, "days")
      ).toISOString(),
      to: new Date(
        firstDateOfMonth.clone().endOf("week").add(1, "days")
      ).toISOString(),
    });
    if (errCode) {
      return notification("error", errDetail);
    }
    listCount.push(result.answers);
    firstDateOfMonth = firstDateOfMonth.endOf("week").add(1, "days");
  }
  yield put({ type: actions.SET_STATISTIC, payload: listCount });
}
export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.GET_LIST_ANSWER, fetchListAnswer),
    yield takeLatest(actions.GET_STATISTIC, fetchListStatistic),
  ]);
}
