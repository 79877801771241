import actions from "./actions";

const initState = {
  listAnswer: [],
  isLoading: false,
  isLoadingStatistic: false,
  listStatistic: [],
};

export default function bookReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_LIST_ANSWER:
      return {
        ...state,
        listAnswer: action.payload,
        isLoading: false,
      };
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_IS_LOADING_STATISTIC:
      return {
        ...state,
        isLoadingStatistic: action.payload,
      };
    case actions.SET_STATISTIC:
      return {
        ...state,
        listStatistic: action.payload,
        isLoadingStatistic: false,
      };
    default:
      return state;
  }
}
