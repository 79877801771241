import axios from "./axios";
import { urlFile,urlImage } from "./helperFunction";
import { objectToQueryString } from "../library/helpers/formatData.js";
import moment from "moment";
const noImageURL = "../../images/noImage.jpg"
const API_URL = process.env.REACT_APP_API_URL;
export const getListLink = async (query=null) => {
  try {
    let url = `${API_URL}`+"link"
    if(query!==null){
      url+=`?${objectToQueryString(query)}`
    }
    let { errCode, errDetail, result } = await axios({
      method: "GET",
      url: url,
    });
    result.data = await formatData(result.data)
    
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};
export const setLink = async (body) => {
  try {
    let data =body
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail, result } = await axios({
      method: "POST",
      url: `${API_URL}`+"link",
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};

export const uploadFile = async (body) => {
  try {

    let data =body
    const { errCode, errDetail, result } = await axios({
      method: "POST",
      url: `${API_URL}`+"upload-file",
      headers:
      {
        'Content-Type': 'application/json'
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};


export const deleteLink = async (code) => {
  try {
    const url = `${API_URL}`+"destroy/link/"+code
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail } = await axios({
      method: "PUT",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};


export const updateLink = async (body,code) => {
  try {
    const url = `${API_URL}`+"link/"+code
    let data =body
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail, result } = await axios({
      method: "PATCH",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};

export const getLink = async (code) => {
  try {
    let url = `${API_URL}` + `link/${code}`
    let { errCode, errDetail, result } = await axios({
      method: "GET",
      url: url,
    });
    result.createAt = moment(result.createAt).format('DD/MM/YYYY');
    result.displayThumbnailUrl = process.env.REACT_APP_AWS3 + result.thumbnailUrl
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};


export const formatData=async (result)=>{
  try{
    for(let i =0;i < result.length;i++){
      if(result[i].thumbnailUrl!=null){
        result[i].displayThumbnailUrl= await urlImage(result[i].thumbnailUrl)
      }
      else if(result[i].thumbnailUrl==null){
        result[i].displayThumbnailUrl= noImageURL
      }
      result[i].displayTitle = result[i].title+" - "+result[i].link
    }
    return result
  }catch(error){
    console.log(error)
  }
  
}
