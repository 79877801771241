import axios from "./axios";
import moment from "moment";
import { objectToQueryString } from "../library/helpers/formatData.js";
const API_URL = process.env.REACT_APP_API_URL;
export const getListFollower = async (query=null) => {
  try {
    let url = `${API_URL}`+"follower"
    if(query!==null){
      url+=`?${objectToQueryString(query)}`
    }
    let { errCode, errDetail, result } = await axios({
      method: "GET",
      url: url,
    });
    
    for(let i =0;i<result.data.length;i++){
      result.data[i].createAt = moment(result.data[i].createAt).format('DD/MM/YYYY');
    }

    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};
