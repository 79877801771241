import actions from "./actions";

const initState = {
  listSlider: [],
  isLoading: false,
  currentSlider: {},
};

export default function sliderReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_LIST_SLIDER:
      return {
        ...state,
        listSlider: action.payload,
        isLoading: false,
      };
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_CURRENT_SLIDER:
      return {
        ...state,
        currentSlider: action.payload,
      };
    default:
      return state;
  }
}
