import actions from "./actions";

const initState = {
  listLink: [],
  isLoading: false,
  currentLink: {},
};

export default function LinkReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_LIST_LINK:
      return {
        ...state,
        listLink: action.payload,
        isLoading: false,
      };
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_CURRENT_LINK:
      return {
        ...state,
        currentLink: action.payload,
      };
    default:
      return state;
  }
}
