import { call, put, takeLatest } from "@redux-saga/core/effects";
import { getAll } from "@iso/services/slider";
import { notification } from "@iso/components";
import actions from "./actions";
function* fetchListSlider() {
  yield put({ type: actions.SET_IS_LOADING, payload: true });
  const { result, errCode, errDetail } = yield call(getAll);
  if (errCode) {
    return notification("error", errDetail);
  }
  yield put({ type: actions.SET_LIST_SLIDER, payload: result.data });
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_LIST_SLIDER, fetchListSlider);
}
