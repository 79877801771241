import axios from "./axios";
import moment from "moment";
import { objectToQueryString } from "../library/helpers/formatData.js";
import userpic from '@iso/assets/images/user1.png';
import profilebg from '@iso/assets/images/profile-bg.jpg';
const API_URL = process.env.REACT_APP_API_URL;
export const getListAccount = async (query=null) =>{
    try{
        let url = `${API_URL}`+"admin/account/listing"
        const token = localStorage.getItem("id_token")
        if(query!==null){
          url+=`?${objectToQueryString(query)}`
        }
        const { errCode, errDetail, result } = await axios({
          method: "GET",
          url: url,
          headers: {
            token : token
          }
        });
        result.data.data = await formatData(result.data.data)
        return {
          errCode: errCode,
          errDetail: errDetail,
          result: result.data,
        };
    }catch (error){
        return {
            result: null,
            errCode: 1,
            errDetail: "system error",
        };
    }
}
export const profile = async (query=null) =>{
  try{
      let url = `${API_URL}`+"admin/profile"
      const token = localStorage.getItem("id_token")
      if(query!==null){
        url+=`?${objectToQueryString(query)}`
      }
      let { errCode, errDetail, result } = await axios({
        method: "GET",
        url: url,
        headers: {
          token : token
        }
      });
      result.user.avatar = userpic
      result.user.profile_bg = profilebg
      return {
        errCode: errCode,
        errDetail: errDetail,
        result: result.user,
      };
  }catch (error){
      return {
          result: null,
          errCode: 1,
          errDetail: "system error",
      };
  }
}
export const createAccount = async(data)=>{
  try{
    const token = localStorage.getItem("id_token")
    let url = `${API_URL}`+"user/register"
    const { errCode, errDetail, result } = await axios({
      method: "POST",
      url: url,
      headers: {
        'Content-Type': 'application/json',
        token : token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
}

export const unActive = async(code)=>{
  try{
    const token = localStorage.getItem("id_token")
    let url = `${API_URL}`+"admin/account/unactive"
    const { errCode, errDetail } = await axios({
      method: "POST",
      url: url,
      headers: {
        'Content-Type': 'application/json',
        token : token
      },
      data: {
        code: code
      },
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
    };
  } catch (error) {
    return {
      errCode: 1,
      errDetail: "system error",
    };
  }
}

export const active = async(code)=>{
  try{
    const token = localStorage.getItem("id_token")
    let url = `${API_URL}`+"admin/account/active"
    const { errCode, errDetail } = await axios({
      method: "POST",
      url: url,
      headers: {
        'Content-Type': 'application/json',
        token : token
      },
      data: {
        code: code
      },
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
    };
  } catch (error) {
    return {
      errCode: 1,
      errDetail: "system error",
    };
  }
}
export const updateAccountByAdmin = async (data, code)=>{
  try{
    const token = localStorage.getItem("id_token")
    // let url = `${API_URL}`+"user/register"
    let url = `${API_URL}`+"admin/update/"+code
    const { errCode, errDetail, result } = await axios({
      method: "patch",
      url: url,
      headers: {
        'Content-Type': 'application/json',
        token : token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
}

export const updateProfile = async (data, code)=>{
  try{
    const token = localStorage.getItem("id_token")
    // let url = `${API_URL}`+"user/register"
    let url = `${API_URL}`+"profile/update/"+code
    const { errCode, errDetail, result } = await axios({
      method: "patch",
      url: url,
      headers: {
        'Content-Type': 'application/json',
        token : token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
}

export const changePass = async (data, code)=>{
  try{
    const token = localStorage.getItem("id_token")
    // let url = `${API_URL}`+"user/register"
    let url = `${API_URL}`+"account/changepass/"+code
    const { errCode, errDetail, result } = await axios({
      method: "post",
      url: url,
      headers: {
        'Content-Type': 'application/json',
        token : token
      },
      data: data,
    });
    console.log(result)
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
}

export const formatData=async (result)=>{
    try{
      for(let i =0;i < result.length;i++){
        result[i].createAt = moment(result[i].createAt).format('DD/MM/YYYY');
        if(result[i].deletedAt ){
          result[i].active = "Unactive"
        }else{
          result[i].active = "Active"
        }
        if(result[i].emailVerify===true){
          result[i].verify = "Verified"
        }else{
          result[i].verify = "Unverified"
        } 
      }
      return result
    }catch(error){
      console.log(error)
    }

}