import actions from "./actions";

const initState = {
  listAdmin: [],
  isLoading: false,
  currentAdmin: {},
};

export default function adminReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_LIST_ADMIN:
      return {
        ...state,
        listAdmin: action.payload,
        isLoading: false,
      };
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_CURRENT_ADMIN:
      return {
        ...state,
        currentAdmin: action.payload,
      };
    default:
      return state;
  }
}
