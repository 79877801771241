import { call, put, takeLatest } from "@redux-saga/core/effects";
import { getListBook } from "@iso/services/book";
import { notification } from "@iso/components";
import actions from "./actions";

function* fetchListBook(action) {
  yield put({ type: actions.SET_IS_LOADING, payload: true });
  const page = action && action?.payload?.page ? action.payload.page : 1
  let {errCode, errDetail, result } = yield call(getListBook, {
    page: page,
    limit: 10
  });

  
  if (errCode) {
    return notification("error", errDetail);
  }
  yield put({ type: actions.SET_LIST_BOOK, payload: result.data });
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_LIST_BOOK, fetchListBook);
  //yield takeLatest(actions.)
}
