import { call, put, takeLatest } from "@redux-saga/core/effects";
import { getListBlog } from "@iso/services/blog";
import { notification } from "@iso/components";
import actions from "./actions";

function* fetchListBlog() {
  yield put({ type: actions.SET_IS_LOADING, payload: true });
  let {errCode, errDetail, result } = yield call(getListBlog);
  if (errCode) {
    return notification("error", errDetail);
  }
  yield put({ type: actions.SET_LIST_BLOG, payload: result.data });
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_LIST_BLOG, fetchListBlog);
}
