import actions from './actions';

const INITIAL_DATA = {
  data: {},
  loading: true,
  error: null,
  isLoading: false,
};
export default function profileReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_CURRENT_PROFILE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}
