import { call, put, takeLatest } from "@redux-saga/core/effects";
import { getAll} from "@iso/services/category";
import { notification } from "@iso/components";
import actions from "./actions";
function* fetchListCategory() {
  yield put({ type: actions.SET_IS_LOADING, payload: true });
  const { result, errCode, errDetail } = yield call(getAll);
  console.log(result)
  if (errCode) {
    return notification("error", errDetail);
  }
  yield put({ type: actions.SET_LIST_CATEGORY, payload: result.data });
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_LIST_CATEGORY, fetchListCategory);
}
