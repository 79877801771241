import { call, put, takeLatest } from "@redux-saga/core/effects";
import { getListUser } from "@iso/services/reader";
import { notification } from "@iso/components";
import actions from "./actions";
function* fetchListUser() {
  yield put({ type: actions.SET_IS_LOADING, payload: true });
  const { result, errCode, errDetail } = yield call(getListUser);
  if (errCode) {
    return notification("error", errDetail);
  }
  yield put({ type: actions.SET_LIST_USER, payload: result.data });
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_LIST_USER, fetchListUser);
}
