import { call, put, takeLatest } from "@redux-saga/core/effects";
import { getListContent } from "@iso/services/content";
import { notification } from "@iso/components";
import actions from "./actions";

function* fetchListContent() {
  yield put({ type: actions.SET_IS_LOADING, payload: true });
  let {errCode, errDetail, result } = yield call(getListContent);

  
  if (errCode) {
    return notification("error", errDetail);
  }
  yield put({ type: actions.SET_LIST_CONTENT, payload: result });
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_LIST_CONTENT, fetchListContent);
}
