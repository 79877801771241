const REDUCER = "@BOOK/";

const actions = {
  GET_LIST_BOOK: `${REDUCER}GET_LIST_BOOK`,
  SET_LIST_BOOK: `${REDUCER}SET_LIST_BOOK`,
  SET_LIST_CATEGORIES: `${REDUCER}SET_LIST_CATEGORIES`,
  SET_IS_LOADING: `${REDUCER}SET_IS_LOADING`,
  SET_CURRENT_BOOK: `${REDUCER}SET_CURRENT_BOOK`,
  SET_CHECKED: `${REDUCER}SET_CHECKED`,
  SET_PAGE: `${REDUCER}SET_PAGE`,
};
export default actions;
