import actions from "./actions";

const initState = {
  page: 1,
  listBook: [],
  isLoading: false,
  currentBook: {},
  checked: false,
  category: [],
};

export default function BookReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_LIST_BOOK:
      return {
        ...state,
        listBook: action.payload,
        isLoading: false,
      };
    case actions.SET_LIST_CATEGORIES:
    return {
      ...state,
      category: action.payload,
    };
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_CURRENT_BOOK:
      return {
        ...state,
        currentBook: action.payload,
        
      };
    case actions.SET_CHECKED:
      return{
        ...state,
        checked:action.payload,
      }
    case actions.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    default:
      return state;
  }
}
