import axios from "./axios";
import moment from "moment";
import { objectToQueryString } from "../library/helpers/formatData.js";
import { urlImage } from "./helperFunction";
const noImageURL = "/../images/noImage.jpg"
const API_URL = process.env.REACT_APP_API_URL;

export const getListBook = async (query=null) => {
  try {
    let url = `${API_URL}`+"admin/books"
    // let url = "http://localhost:80/api/v1/admin/books"
    const token = localStorage.getItem("id_token")
    if(query!==null){
      url+=`?${objectToQueryString(query)}`

    }
    const { errCode, errDetail, result } = await axios({
      method: "GET",
      url: url,
      headers: {
        token: token
      }
    });
    result.data = await formatData(result.data)
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};



export const setBook = async (body) => {
  try {
    let data =body
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail, result } = await axios({
      method: "POST",
      url: `${API_URL}`+"book",
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};

export const updateBook = async (body,code) => {
  try {
    const url = `${API_URL}`+"book/"+code
    let data =body
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail, result } = await axios({
      method: "PATCH",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};


export const deleteBook = async (code) => {
  try {
    const url = `${API_URL}`+"destroy/book/"+code
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail } = await axios({
      method: "PUT",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};


export const formatData=async (result)=>{
  try{
    for(let i =0;i < result.length;i++){
      if(result[i].thumbnailUrl!==null){
        result[i].displayThumbnailUrl= await urlImage(result[i].thumbnailUrl)
      }
      else if(result[i].thumbnailUrl===null){
        result[i].displayThumbnailUrl= noImageURL
      }
      if(result[i].state===true){
        result[i].displayState="Public"
      }
      else if(result[i].state===false){
        result[i].displayState="Private"
      }
      result[i].releaseDate = moment(result[i].releaseDate).format('DD/MM/YYYY');
    }
    return result
  }catch(error){
    console.log(error)
    return null;
  }
  
}

export const checkSlug = async ({ slug, code  = null}) => {
  try {
    const url = code ? `${API_URL}slug/${code}/${slug}` : `${API_URL}slug/${slug}`
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail } = await axios({
      method: "GET",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};