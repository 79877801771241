import { call, put, takeLatest } from "@redux-saga/core/effects";
import { getListFollower } from "@iso/services/follower";
import { notification } from "@iso/components";
import actions from "./actions";
function* fetchListFollower() {
  yield put({ type: actions.SET_IS_LOADING, payload: true });
  const { result, errCode, errDetail } = yield call(getListFollower);
  if (errCode) {
    return notification("error", errDetail);
  }
  yield put({ type: actions.SET_LIST_FOLLOWER, payload: result.data });
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_LIST_FOLLOWER, fetchListFollower);
}
