import { all } from "redux-saga/effects";
import authSagas from "@iso/redux/auth/saga";
import readerSagas from "@iso/redux/reader/saga";
import followerSagas from "@iso/redux/follower/saga";
import adminSagas from "@iso/redux/admin/saga";
import bookSagas from "@iso/redux/book/saga";
import answerSagas from "@iso/redux/answer/saga";
import contentSagas from "@iso/redux/content/saga";
import linkSagas from "@iso/redux/otherLink/saga";
import blogSagas from "@iso/redux/blog/saga";
import categorySagas from "@iso/redux/category/saga";
import sliderSagas from "@iso/redux/slider/saga";
import profileSagas from "@iso/redux/profile/saga"
export default function* rootSaga(getState) {
  yield all([
    authSagas(), 
    readerSagas(), 
    adminSagas(), 
    bookSagas(), 
    answerSagas(),
    contentSagas(),
    linkSagas(), 
    blogSagas(),
    categorySagas(),
    sliderSagas(),
    profileSagas(),
    followerSagas()
  ]);
}
