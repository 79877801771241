import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import reader from "@iso/redux/reader/reducer";
import follower from "@iso/redux/follower/reducer";
import admin from "@iso/redux/admin/reducer";
import book from "@iso/redux/book/reducer";
import answer from "@iso/redux/answer/reducer";
import content from "@iso/redux/content/reducer";
import link from "@iso/redux/otherLink/reducer";
import blog from "@iso/redux/blog/reducer";
import category from "@iso/redux/category/reducer";
import slider from "@iso/redux/slider/reducer";
import profile from "@iso/redux/profile/reducer";
export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  reader,
  admin,
  book,
  answer,
  content,
  link,
  blog,
  category,
  slider,
  profile,
  follower
});
