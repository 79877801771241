// saga.js
import { call, put, takeLatest  } from 'redux-saga/effects';
import actions from './actions';
import { profile } from "@iso/services/admin";
import { notification } from "@iso/components";

function* fetchProfile() {
  yield put({ type: actions.SET_IS_LOADING, payload: true });
  const { result, errCode, errDetail } = yield call(profile);
  if (errCode) {
    return notification("error", errDetail);
  }
  yield put({ type: actions.SET_CURRENT_PROFILE, payload: result});
  yield  put({ type: actions.SET_IS_LOADING, payload: false });
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_CURRENT_PROFILE, fetchProfile);
}
