import axios from "./axios";
import moment from "moment";
import { objectToQueryString } from "../library/helpers/formatData.js";
const API_URL = process.env.REACT_APP_API_URL;

export const getAll = async(query=null)=>{
    try{
        let url = `${API_URL}`+"category"
        //const token = localStorage.getItem("id_token")
        if(query!==null){
            url+=`?${objectToQueryString(query)}`
        }
        const { errCode, errDetail, result } = await axios({
            method: "GET",
            url: url,
        });

        return {
            errCode: errCode,
            errDetail: errDetail,
            result: result,
        };
    }catch (error){
      return {
          result: null,
          errCode: 1,
          errDetail: "system error",
      };
    }
}


export const deleteCategory = async (code) => {
  try {
    const url = `${API_URL}`+"destroy/category/"+code
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail } = await axios({
      method: "PUT",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};


export const createCategory = async (body) => {
    try {
      let data =body
      const token = localStorage.getItem("id_token")
      const { errCode, errDetail, result } = await axios({
        method: "POST",
        url: `${API_URL}`+"category",
        headers:
        {
          'Content-Type': 'application/json',
          token: token
        },
        data: data,
      });
      return {
        errCode: errCode,
        errDetail: errDetail,
        result: result,
      };
    } catch(error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};

export const updateCategory = async (body,code) => {
  try {
    const url = `${API_URL}`+"category/"+code
    let data =body
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail, result } = await axios({
      method: "PATCH",
      url: url,
      headers:
      {
        'Content-Type': 'application/json',
        token: token
      },
      data: data,
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
      result: result,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};

export const checkCateSlug = async (slug, code) => {
  try {
    const url = code ? `${API_URL}category/checkSlug/${slug}/${code}` : `${API_URL}category/checkSlug/${slug}`
    const token = localStorage.getItem("id_token")
    const { errCode, errDetail } = await axios({
      method: "GET",
      url: url,
      headers:
        {
          'Content-Type': 'application/json',
          token: token
        },
    });
    return {
      errCode: errCode,
      errDetail: errDetail,
    };
  } catch (error) {
    return {
      result: null,
      errCode: 1,
      errDetail: "system error",
    };
  }
};